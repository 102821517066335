<template>
  <ul class="price-list">
    <li class="price-list__item">
      <span class="font-12 text-muted">Min. Fiyat</span>
      <span class="font-weight-bold ml-2 float-right">{{
        getMinimumPrice
      }}</span>
    </li>
    <li class="price-list__item">
      <span class="font-12 text-muted">Ort. Fiyat</span>
      <span class="font-weight-bold ml-2 float-right">{{
        getaveragePrice
      }}</span>
    </li>
    <li class="price-list__item">
      <span class="font-12 text-muted">Maks. Fiyat</span>
      <span class="font-weight-bold ml-2 float-right">{{
        getMaximumPrice
      }}</span>
    </li>
  </ul>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "PriceList",
  data() {
    return {
      minimumPrice: 0,
      averagePrice: 0,
      maximumPrice: 0,
    };
  },
  props: {
    competitors: {
      default: () => [],
    },
    productPrice: {
      default: 0,
    },
    currency: {
      default: "TRY",
    },
  },
  methods: {
    getCompetitorPrices() {
      let result = [];

      if (this.competitors && this.competitors.length) {
        result = this.competitors
          .filter((item) => item.price && !isNaN(item.price))
          .map((i) =>
            i.hasOwnProperty("convertedCurrency")
              ? i.convertedPrice || 0
              : i.price
          );
      }

      return result;
    },
    setMinimumPrice() {
      let result = null;
      let competitorPrices = this.getCompetitorPrices();

      if (competitorPrices && competitorPrices.length) {
        competitorPrices.forEach((price) => {
          if (result === null) {
            result = price;
          } else {
            if (price < result) {
              result = price;
            }
          }
        });
      } else {
        result = "-";
      }

      this.minimumPrice = result;
    },
    setAveragePrice() {
      let result = "-";
      let competitorPrices = this.getCompetitorPrices();

      if (competitorPrices && competitorPrices.length) {
        result =
          competitorPrices.reduce(function (a, b) {
            return a + b;
          }) / competitorPrices.length;
      } else {
        result = "-";
      }

      // Rakip fiyatlarının hatalı olduğunun anlaşılması için ortalama fiyat yoksa 0 gönderilmeli
      this.$emit("average-price", !isNaN(result) ? result : 0);

      this.averagePrice = result;
    },
    setMaximumPrice() {
      let result = null;
      let competitorPrices = this.getCompetitorPrices();

      if (competitorPrices && competitorPrices.length) {
        competitorPrices.forEach((price) => {
          if (result === null) {
            result = price;
          } else {
            if (price > result) {
              result = price;
            }
          }
        });
      } else {
        result = "-";
      }

      this.maximumPrice = result;
    },
    setPriceList() {
      this.setMinimumPrice();
      this.setAveragePrice();
      this.setMaximumPrice();
    },
  },
  computed: {
    ...mapState(["priceAnalysis"]),
    getMinimumPrice() {
      return this.minimumPrice > 0
        ? this.$filters.moneyFormat(this.minimumPrice)
        : "-";
    },
    getaveragePrice() {
      return this.averagePrice > 0
        ? this.$filters.moneyFormat(this.averagePrice)
        : "-";
    },
    getMaximumPrice() {
      return this.maximumPrice > 0
        ? this.$filters.moneyFormat(this.maximumPrice)
        : "-";
    },
  },
  watch: {
    "priceAnalysis.products": {
      handler: function (comps) {
        this.setPriceList();
      },
      deep: true,
    },
  },
  mounted() {
    this.setPriceList();
  },
};
</script>